import React, { useEffect } from 'react'
import NumberCard from '../numberCard'
import Aos from 'aos'

const Numbers = () => {
  useEffect(() => {
    Aos.init();
  }, [])
  
  return (
    <section className='number-section'>
      <div data-aos-duration="500" data-aos="fade-right">
        <NumberCard number="700" unit="Trillion Cubic Ft." title='Potential Reserves' />
      </div>

      <div data-aos-duration="500" data-aos="fade-right" data-aos-delay="100">
        <NumberCard number="$4" unit="TRILLION" title='Potential Value' />
      </div>

      <div data-aos-duration="500" data-aos="fade-right" data-aos-delay="200">
        <NumberCard number="1" title='Patents' />
      </div>

      {/* <div data-aos-duration="500" data-aos="fade-right" data-aos-delay="300">
        <NumberCard number="36%" title='Global Energy from Coal' />
      </div>

      <div data-aos-duration="500" data-aos="fade-right" data-aos-delay="400">
        <NumberCard number="-46%" title='Gas vs Coal CO2 per kWh' />
      </div> */}
      <div data-aos-duration="500" data-aos="fade-right" data-aos-delay="400">
        <NumberCard number="160x" title='MH energy per unit compared to Natural Gas' />
      </div>
    </section>
  )
}

export default Numbers
