import React from "react";
import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import inside from "../../assets/img/esg-inside.png";
import PrimaryBgHeading from "../primaryBgHeading";
import svg1 from '../../assets/img/svg/svg1.svg';
import svg2 from '../../assets/img/svg/svg2.svg';
import svg3 from '../../assets/img/svg/svg3.svg';
import svg4 from '../../assets/img/svg/svg4.svg';
import svg5 from '../../assets/img/svg/svg5.svg';
import svg6 from '../../assets/img/svg/svg6.svg';
import svg7 from '../../assets/img/svg/svg7.svg';
import svg8 from '../../assets/img/svg/svg8.svg';
import svg9 from '../../assets/img/svg/svg9.svg';
import file_svg from '../../assets/img/file.svg';
import IconBox from "./IconBox";
import Bottom from "../home/Bottom";
import Loader from "../layout/loader";

const ESG = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="ADVANCING RESPONSIBILITY" className='esg-banner'/>
      <PageHeading
        heading="TransEnergy360 offers immediate benefits by reducing global dependence on coal for energy production, eliminates energy insecurity, and offers a cost-effective solution for other renewable energy solutions."
        align="text-start"
      />

      <div className="about-owner">
        <div className="about-heading-wrap">
          <div className="about-heading">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="text-center">
              DELEVERING SUSTAINABLE SOLUTIONS
              </p>
            </div>
          </div>
        </div>

        <section className="brief-section esg-delivering">
          <Row className="g-0">
            <Col lg="6">
              <div
                data-aos-duration="700"
                data-aos="fade-right"
                className="h-100"
              >
                <div className="brief-content-wrap">
                  <h4 className="brief-text">
                  Global Energy Security – Expand access to the supply of energy to the world to eliminate conflict and energy poverty
                  </h4>
                  <a href="/" className="text-btn">
                    KNOW MORE{" "}
                    <svg
                      width="22"
                      height="12"
                      viewBox="0 0 22 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 6H21M21 6L16.102 1M21 6L16.102 11"
                        stroke="#77ABD2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </Col>

            <Col lg="6">
              <div data-aos-duration="700" data-aos="fade-left">
                <div className="img-wrap">
                  <img src={inside} alt="" className="brief-img" />
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <div className="mission-wrap">
          <div className="mission-content">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="small-info-text">
              • Environmental - Safely extract a resource to avoid devastating natural escape
              </p>
              <p className="small-info-text">
              • Other - Byproducts create the resources to expand crop production to feed a growing population and to increase forestation
              </p>
              <p className="small-info-text">
              • Carbon Capture – Forestation creates a carbon sink resulting in negative emissions
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="playbook-wrap">
        <div className="about-content">
          <div data-aos-duration="700" data-aos="fade-up">
            <h4 className="about-title"> A Unique ESG Playbook </h4>
          </div>

          <div data-aos-duration="700" data-aos="fade-up">
            <p className="about-desc">
              {" "}
              Globally abundant methane hydrates deliver global energy security, lower cost and lower carbon emissions than coal leading to a healthier, happier planet.             </p>
          </div>
        </div>

        <div className="d-flex flex-md-row flex-column align-items-lg-start align-items-center justify-content-lg-around justify-content-md-between svg-wrap">
          <div data-aos-duration="700" data-aos="fade-right">
            <div className="svg-link mb-4 mb-md-0 statement">
              <img src = {file_svg} alt="file"/>
              <p>View our ESG Statement of Purpose</p>
            </div>
          </div>

          <div
            data-aos-duration="700"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <div className="svg-link statement">
            <img src = {file_svg} alt="file"/>
            <p>View our 2021 ESG Report</p>
            </div>
          </div>
        </div>
      </div> */}

     

      <div data-aos-duration="700" data-aos="fade-up">
        <div className="mt-3 playbook-wrap">
          <Row>
            <Col lg="12">
              <p className="small-info-text">
              Using methane for electricity can immediately reduce carbon emissions by replacing coal as the global leader in energy production while we continue our development of alternative emmission free energy sources. Additionally, the byproducts support food production, can efficiently drive desalination to produce water, and lead to reforestation. 
              </p>
            </Col>
          </Row>
        </div>
      </div>

      <PrimaryBgHeading heading="Key benefits of Methane Hydrates: " />

      <div className="focus-area">
        <Row className="gx-4 gy-5 justify-content-center">
          <Col  lg="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p><strong>Abundance:</strong></p>
              <p>Methane hydrate is found in large deposits around the world, particularly in offshore areas and in the permafrost of polar regions. This means that it is a potentially abundant and sustainable source of energy.</p>

            </div>
          </Col>
          <Col  lg="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p><strong>High energy density:</strong></p>
              <p>Methane hydrate has a higher energy density than other forms of natural gas, which means that it can provide more energy per unit of volume. This can make it a more efficient and cost-effective source of energy.</p>

            </div>
          </Col>
          <Col  lg="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p><strong>Low emissions:</strong></p>
              <p>Methane is a clean-burning fuel, and the extraction and use of methane hydrate can produce fewer greenhouse gas emissions than other forms of energy. This can help to reduce air pollution and mitigate climate change. </p>

            </div>
          </Col>
          <Col  lg="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p><strong>Flexibility:</strong></p>
              <p>Methane hydrate can be extracted and used in a variety of ways, such as for power generation, heating, or transportation. This flexibility can make it a valuable energy source for a range of applications.</p>

            </div>
          </Col>
          <Col  lg="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p><strong>Potential cost savings:</strong></p>
              <p>As methane hydrate technology advances and becomes more widely used, it could become a more affordable and competitive source of energy compared to other fossil fuels. This could lead to cost savings for consumers and businesses.</p>

            </div>
          </Col>
         
         
        </Row>
      </div>
      <br/>
      <Bottom />
    </>
  );
};

export default ESG;
