import React, { useEffect } from 'react'
import icon from '../../assets/img/logo-icon.png'
import Aos from 'aos';

const About = (props) => {
  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <section className='about-section'>
      <div data-aos="fade-up" data-aos-duration="500">
        <div className="about-wrap">
              <img src={icon} alt="logo" className='logo-icon' />

              <div className="about-content">
                  <h4 className="about-title"> {props.title} </h4>
                  <p className="about-desc"> {props.desc1}</p>
                  <p className="about-desc">{props.desc2}</p>
                  {props.desc3 && <p className="about-desc">{props.desc3}</p>}
                  {props.desc4 && <p className="about-desc">{props.desc4}</p>}
                  {props.desc5 && <p className="about-desc">{props.desc5}</p>}
              </div>
          </div>
        </div>
    </section>
  )
}

export default About
