import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import img from "../../assets/img/flashplant.png";
import rhem1 from "../../assets/img/rhem1.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Bottom from "../home/Bottom";
import Loader from "../layout/loader";
import PrimaryBgHeading from '../primaryBgHeading'

const Rhem = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="REHM TECHNOLOGY" />
      <PageHeading
        heading="REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. Resulting methane is used for power generation, with emissions converted to fertilizers for afforestation, and the surplus reinjected underground."
        align="text-start"
      />
         <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
        <Col xxl="7">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
              The process will be self-sustaining using biofuels when forestation is initiated. It is globally applicable due to presence of hydrates near population centers and decentralized community operated projects are visualized. It should be noted that large quantities of hydrates are under the ocean that is contained as solids due to equilibrium with pressure and temperature conditions which is being upset due to ocean (global) warming leading to release of large volumes of harmful gaseous methane to the atmosphere. REHM Technology enables these hydrates to be produced safely and use as a beneficial primary energy source.
              </p>
            </div>
          </Col>

          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img src={rhem1} alt="dry steam plant" className="plant-info-img" />
            </div>
          </Col>
          </Row>
          </div>
          <PrimaryBgHeading heading="CONCEPT SUMMARY" />
          <PageHeading
        heading="Climate change can be mitigated via application of Resonance Hydrates Methane (REHM) technology to deep-water methane hydrate deposits, harnessing the most abundant hydrocarbon energy source."
        align="text-start"
      />
          <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          
          <Col xxl="12">
            <div data-aos-duration="700" data-aos="fade-up">

            <p className="detail-info-text">
       REHM uses electro magnetic resonance (EMR) to transfer microwave energy to break the methane-water bond, held together by weak Van der Waal forces. Selective energy transfer happens at a specific point in the spectra. The product - methane gas – can be used to fuel gas turbines for power generation or produce fertilizers.
              </p>
              <p className="detail-info-text">
              In the past, the industry used alternate methods, such as depressurization and chemical substitution. These methods showed that production of gaseous methane from hydrate was producible, but not sustainable. This rendered the methods unreliable for commercial use. This unreliability is primarily due to lack of water ingress to the production zone. The water must be at low pressure to dissociate methane from the water. Solving this problem is potentially disruptive, due to the abundance of naturally occurring methane hydrates in deep oceanic water, and their distribution globally. REHM technology is used to extract gaseous methane from globally abundant clathrates (solid hydrates).
              </p>
            </div>
          </Col>

         

      
        </Row>
      </div>
     
     

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="5">
            <div data-aos-duration="700" data-aos="fade-up">
              <img src={img} alt="dry steam plant" className="plant-info-img" />
            </div>
          </Col>

          <Col xxl="6">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <p className="detail-info-text">
              The impact of this new technology will be immense in the developing world. In countries like India, Korea, Japan and China, local communities will be self-reliant for energy, potable water and food. In the USA, methane from hydrates will replace depleting gas resources, utilizing primarily existing energy infrastructure – all with no ground water impact. In Europe, growing energy demands will be satisfied, and obsolete facilities will be replaced - achieving zero emissions.
              </p>
            </div>
          </Col>

        
        </Row>
      </div>

      <DetailBottomBanner
        title="OTHER TECHNOLOGIES"
        techBox1={
          <TechnologyBox
            link="/technology/arcs"
            title="ARCS TECHNOLOGY"
            desc="Arcs provides a means to use solar energy captured by the oceans for emission-free power generation."
          />
        }
        techBox2={
          <TechnologyBox
            link="/technology/swers"
            title="SWERS TECHNOLOGY"
            desc="SWERS provides the ability to save water and recover waste energy in thermal power plants."
          />
        }
      />

      <Bottom />
    </>
  );
};

export default Rhem;
