import React from 'react'

const Insights = () => {
  return (
    <section className='insight-section'>
      <div data-aos-duration="500" data-aos="fade-up">
        <h3 className="insight-title">Coal is still the leading source of global energy production and has held steady at roughly 36% of global energy production since 1985. Gas production via Methane Hydrate extraction can replace coal as the leading source of electricity production globally leading to a significant reduction in carbon emissions. </h3>
      </div>
    </section>
  )
}

export default Insights
