const Team = [
  {
    id: 1,
    name: "Wickrema Singhe",
    position: "Founder and CTO",
    img_path: require("../../assets/img/team/Wick.png"),
    category: "founder",
    linkedIn: "https://www.linkedin.com/in/wickrema-singhe-66a784/",
  },
  {
    id: 2,
    name: "Dr. Jay Patel",
    position: "Chairman & CEO",
    img_path: require("../../assets/img/team/jayy.png"),
    category: "founder",
    linkedIn: "https://www.linkedin.com/in/jay-patel-a64450146/",
  },
  // {
  //   id: 8,
  //   name: "Frank Monachelli",
  //   position: "Chief Financial Officer ",
  //   img_path: require("../../assets/img/team/Frank.png"),
  //   category: "innovation",
  //   linkedIn: "https://www.linkedin.com/in/frankmonachelli/",
  // },
  // {
  //   id: 4,
  //   name: "Darrell Gordon",
  //   position: "Head of Accounting",
  //   img_path: require("../../assets/img/team/Darrell.png"),
  //   category: "royalties",
  //   linkedIn: "https://www.linkedin.com/in/darrell-gordon-204871a8/",
  // },
  // {
  //   id: 10,
  //   name: "Mohan Anem",
  //   position: "International Financial Advisor",
  //   img_path: require("../../assets/img/team/Mohan.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/mohananem/",
  // },

  // {
  //   id: 9,
  //   name: "Trey Storer",
  //   position: " Head of Business Development ",
  //   img_path: require("../../assets/img/team/treyy.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/trey-storer/",
  // },
  {
    id: 3,
    name: "Anil Ali",
    position: "Legal Counsel",
    img_path: require("../../assets/img/team/Anel.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/anil-ali-a076364/",
  },
  {
    id: 2,
    name: "Kirti Patel",
    position: "Technical Advisor",
    img_path: require("../../assets/img/team/Kriti.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/kirti-patel-220238177/",
  },

  {
    id: 3,
    name: "Mayur Patel",
    position: "Head Of Operation",
    img_path: require("../../assets/img/team/mayur.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/mayur-patel-273746235/",
  },
  // {
  //   id: 3,
  //   name: "Dr. Andrew Davidoff",
  //   position: "Geological Advisor",
  //   img_path: require("../../assets/img/team/Andrew.png"),
  //   category: "capital",
  //   linkedIn: "https://www.linkedin.com/in/andrew-joseph-davidoff/",
  // },
  // {
  //   id: 3,
  //   name: "Rajesh Desai",
  //   position: "EPC Advisor",
  //   img_path: require("../../assets/img/team/Rajesh.png"),
  //   category: "capital",
  //   linkedIn: "https://www.linkedin.com/in/raj-desai-56728a5/",
  // },
  {
    id: 11,
    name: "Manish Mishra",
    position: "International Strategy",
    img_path: require("../../assets/img/team/manish.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/manishmishraenergyallied/",
  },
  // {
  //   id: 11,
  //   name: "Syed Ali",
  //   position: "Advisor",
  //   img_path: require("../../assets/img/team/syed-ali.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/syed-ali-a09a73159/",
  // },
];

export default Team;
