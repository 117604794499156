import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import swers1 from "../../assets/img/swers1.png";
import swers2 from "../../assets/img/swers2.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Bottom from "../home/Bottom";
import Loader from "../layout/loader";
import PrimaryBgHeading from "../primaryBgHeading";

const Swers = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="SWERS TECHNOLOGY" />
      <PageHeading
        heading="SWERS provides the ability to save water and recover waste energy in thermal power plants. Primary water loss is due to evaporation in the cooling tower."
        align="text-start"
      />

<div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
        <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img src={swers1} alt="dry steam plant" className="plant-info-img" />
            </div>
          </Col>
          <Col xxl="7">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
              In the SWERS design, this cooling water is eliminated, and instead, the steam condenser is cooled using a refrigerant. The absorption evaporates refrigerant, providing energy to drive an independent turbo expander that generates power. Results in elimination of water lose in thermal power plants (of all types) and recovers 15% more energy from a unit of emission, presently experienced. SWERS could be retrofitted to existing power plants.
              </p>
            </div>
          </Col>

        

         
        </Row>
      </div>
      <PrimaryBgHeading heading="CONCEPT SUMMARY" className='pt-0 pb-0'/>
      <PageHeading
        heading="Within a thermal power plant, a condenser accounts for approximately 30% of the heat load. In current models, this heat is wasted. The condenser is cooled using water running in a loop that rejects heat to the atmosphere, via a cooling tower."
        align="text-start"
      />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
        <Col md='12'>
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
              SWERS eliminates the use of water for condensing steam and recovers waste heat for generating additional power. The process is replaced by a closed loop refrigeration circuit operating a turbo expander that generates additional electrical power from the condenser waste heat, thereby, saving water, increasing power output, and minimizing.
              </p>
            </div>
          </Col>

         

          <Col xxl="12">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img src={swers2} alt="dry steam plant" className="plant-info-img" />
            </div>
          </Col>

         
        </Row>
      </div>

      <DetailBottomBanner
        title="OTHER TECHNOLOGIES"
        techBox1={
          <TechnologyBox
            link="/technology/rehm"
            title="REHM TECHNOLOGY"
            desc="REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. "
          />
        }
        techBox2={
          <TechnologyBox
            link="/technology/arcs"
            title="ARCS TECHNOLOGY"
            desc="Arcs provides a means to use solar energy captured by the oceans for emission-free power generation. "
        
          />
        }
      />

      <Bottom />
    </>
  );
}

export default Swers