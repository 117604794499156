import React from "react";
import Slider from "react-slick";
import PlantSlide from "../plantSlide";
import dry from '../../assets/img/rehmtechnology.png';
import flash from "../../assets/img/arcs1.png";
import binary from "../../assets/img/swers1.png";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        width="14"
        height="26"
        viewBox="0 0 14 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L13 13L1 25"
          stroke="#77ABD2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        width="14"
        height="26"
        viewBox="0 0 14 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 25L1 13L13 0.999999"
          stroke="#77ABD2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

const Plants = ({className}) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section className="plant-section">
      <Slider {...settings}>
        <PlantSlide
          className={className}
          title="REHM Technology"
          about="The TransEnergy solution solves the prior challenges through patented Resonance Electromagnetic Hydrate Methane (REHM) technology. This patented solution leverages existing concepts and technologies in new ways to solve the Methane Hydrate extraction challenge."
          img={dry}
          link="/technology/rehm"
        />
        <PlantSlide
          title="ARCS Technology"
          about="Earth is some 70% ocean; ARCS utilizes the thermal gradient in deep oceanic waters for direct operation of power-generating turbines, using refrigerant-based thermosiphons. "
          img={flash}
          link="/technology/arcs"
        />
        <PlantSlide
          title="SWERS Technology"
          about="SWERS PROVIDES THE ABILITY TO SAVE WATER AND RECOVER WASTE ENERGY IN THERMAL POWER PLANTS. PRIMARY WATER LOSS IS DUE TO EVAPORATION IN THE COOLING TOWER."
          img={binary}
          link="/technology/swers"
        />
      </Slider>
    </section>
  );
};

export default Plants;
