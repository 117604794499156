import React from 'react'
import bg from '../../assets/img/card-bg.png'

const NumberCard = (props) => {
  return (
    <div className='number-card'>
      <div className="number-box">
        <div className="number-bg">
          <img src={bg} alt="" />
        </div>
        <p className="number"> {props.number} </p>
       {props.unit &&<p className="unit">{props.unit}</p> }
      </div>

      <p className="number-text text-center"> {props.title} </p>
    </div>
  )
}

export default NumberCard
