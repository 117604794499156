import React from "react";
// import {Link} from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
// import Team from "../team";
// import jay from "../../assets/img/team/jayy.png";
// import andy from "../../assets/img/team/Andrew.png";
// import inside from "../../assets/img/inside.png";
import Bottom from "../home/Bottom";
import BottomCard from "../bottomCard";
import advantages_banner from '../../assets/img/advantages-banner.png'
import growth from '../../assets/img/growth.png'
import Loader from "../layout/loader";
import PrimaryBgHeading from "../primaryBgHeading";
import t1 from '../../assets/img/drysteam.png'
import t2 from '../../assets/img/flashsteam.png'
import t3 from '../../assets/img/binary.png'
import TechnologyBox from '../technologyBox'



const Advantages = () => {
  return (
    <>
      <Loader />
      <div className="advantages-wrap">
        <InnerBanner title="COMPETITIVE ADVANTAGE" />
        <PageHeading heading="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
        <div className="page-heading-wrap padding-0">
          <hr />
        </div>
        <PageHeading
          className="padding-0"
          heading="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est"
        />
        <Row className="page-heading-wrap padding-0">
          <Col sm="2"></Col>
          <Col sm="10">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="text">
                Volutpat sed cras ornare arcu dui vivamus arcu felis. Quis enim
                lobortis scelerisque fermentum dui faucibus. Dictum at tempor
                commodo ullamcorper. Tincidunt nunc pulvinar sapien et ligula
                ullamcorper. Sed cras ornare arcu dui vivamus arcu felis. Eu
                facilisis sed odio morbi. Laoreet non curabitur gravida arcu ac
                tortor dignissim convallis aenean.
              </p>
            </div>
          </Col>
        </Row>

        <PrimaryBgHeading heading="TECHNOLOGY WE FOCUS IN" />

        <div className="techno-box-wrap">
        <Row className="gy-4">
          <Col md="6" xl="4">
            <div data-aos-duration="700" data-aos="fade-right">
              <TechnologyBox
                img={t1}
                show="d-block"
                link="/technology/dry-steam"
                title="REHM TECHNOLOGY"
                desc="REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. "
              />
            </div>
          </Col>

          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <TechnologyBox
                img={t2}
                show="d-block"
                link="/technology/flash-steam"
                title="ARCS TECHNOLOGY"
                desc="Arcs provides a means to use solar energy captured by the oceans for emission-free power generation."
              />
            </div>
          </Col>

          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <TechnologyBox
                img={t3}
                show="d-block"
                link="/technology/binary-plant"
                title="SWERS TECHNOLOGY"
                desc="SWERS provides the ability to save water and recover waste energy in thermal power plants."
              />
            </div>
          </Col>
        </Row>
      </div>

        <div className="page-heading-wrap padding-0">
          <hr />
          <div data-aos-duration="700" data-aos="fade-up">
            <p className="growth-title">A GROWING, LONG-TERM OPPORTUNITY SET</p>
          </div>

          <div data-aos-duration="700" data-aos="fade-up" data-aos-delay="200">
            <p className="growth-desc">
              Volutpat sed cras ornare arcu dui vivamus arcu felis. Quis enim
              lobortis scelerisque fermentum dui faucibus. Dictum at tempor
              commodo ullamcorper. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper. Sed cras ornare arcu dui vivamus arcu felis. Eu
              facilisis sed odio morbi. Laoreet non curabitur gravida arcu ac
              tortor dignissim convallis aenean.
            </p>
          </div>
        </div>

        <Row className="page-heading-wrap padding-0 growing-wrap">
          <Col md="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <img className="responsive" src={growth} alt="growth" />
            </div>
          </Col>
        </Row>
        <div>
          <div data-aos-duration="700" data-aos="fade-up">
            <img
              src={advantages_banner}
              alt="advantages_banner"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <Bottom />
      </div>
    </>
  );
};

export default Advantages;
