import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import specific_arc from "../../assets/img/specific-arc.png";
import arcs1 from "../../assets/img/arcs1.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Bottom from "../home/Bottom";
import PrimaryBgHeading from '../primaryBgHeading'

import Loader from "../layout/loader";

const Arcs = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="ARCS TECHNOLOGY" />
      <PageHeading
        heading="Arcs provides a means to use solar energy captured by the oceans for emission-free power generation."
        align="text-start"
      />

<div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
        <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img src={arcs1} alt="dry steam plant" className="plant-info-img" style={{width:'100%'}}/>
            </div>
          </Col>
          <Col xxl="7">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
              Earth is some 70% ocean; ARCS utilizes the thermal gradient in deep oceanic waters for direct operation of power-generating turbines, using refrigerant-based thermosiphons. Present application supplements offshore wind power, to improve system availability, and uses the infinite capacity of the ocean to mitigate the need for energy storage.
              </p>
            </div>
          </Col>

        

         
        </Row>
      </div>
      <PrimaryBgHeading heading="CONCEPT SUMMARY" className='pt-0 pb-0'/>
      <PageHeading
        heading="Applied refrigerant & control system (ARCS) – the use of naturally occurring thermal gradients of deep oceans to generate electrical power to supplement offshore winds that are interrupted by inherently inconsistent weather patterns. Incorporation of secondary arcs systems makes offshore facilities more dependable and mitigates the need for energy storage for load management."
        align="text-start"
      />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
        <Col md='12'>
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
              More than 70% of planet Earth is covered by oceans. This creates a massive inexhaustible solar cell, with thermal gradients that can be harnessed for ARCS, to provide emission-free power generation.
Approximately two-thirds of all humankind live within close proximity to an ocean. ARCS depends on the thermal mass of the ocean, which is nearly constant and location specific. Most equipment needed for ARCS is already located beneath the sea, easily facilitating a pilot project into an existing deep-water platform in the Gulf of Mexico.
              </p>
            </div>
          </Col>

          <Col xxl="6">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
              The specific ARCS design uses refrigerant with subsea thermosiphon reboilers and condensers, and condensate pumps with turbo-expanders. This system was modeled using a Hysys process simulator, using industry accepted software and generalized data to establish feasibility. The intent is to develop the models to incorporate plant- and location-specific data, and generate information for detailed designs. ARCS consists of a unique arrangement to produce reliable emission-free electrical power.
              </p>
            </div>
          </Col>

          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img src={specific_arc} alt="dry steam plant" className="plant-info-img" />
            </div>
          </Col>

         
        </Row>
      </div>

      <DetailBottomBanner
        title="OTHER TECHNOLOGIES"
        techBox1={
          <TechnologyBox
            link="/technology/rehm"
            title="REHM TECHNOLOGY"
            desc="REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. "
          />
        }
        techBox2={
          <TechnologyBox
            link="/technology/swers"
            title="SWERS TECHNOLOGY"
            desc="SWERS provides the ability to save water and recover waste energy in thermal power plants."
          />
        }
      />

      <Bottom />
    </>
  );
};

export default Arcs;
