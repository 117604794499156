import React from "react";
import video from "../../assets/video/Geothermalcore.mp4";
import banner from "../../assets/img/banner-svg.png";
import logo from "../../assets/img/logo.png";
import banner_image from '../../assets/img/banner-image.png'


const Banner = () => {
  return (
    <div className="banner-wrap">
      <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1100">
        {/* <video src={video} loop muted autoPlay className="banner-video" /> */}
        <img src={banner_image} alt="" className="banner-svg" />

      </div>

      {/* <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1200">
        <img src={banner} alt="" className="banner-svg" />
      </div> */}

      <div className="banner-content">
      

        <div data-aos-duration="500" data-aos="fade-right" data-aos-delay="1400">
        <h3 className='banner-title text-center'>Our mission is to use our proprietary technology for methane hydrate extraction to improve global energy security, reduce carbon emission, increase farm productivity and promote reforestation</h3>
        </div>
      </div>
    </div>
  );
};

export default Banner;
