import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Bottom from "../home/Bottom";
import InnerBanner from "../innerBanner";
import Loader from "../layout/loader";
import PageHeading from "../pageHeading";
import Team from './team'
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const TeamPage = () => {
  const [teamMember, setTeamMember] = useState(Team);
  const [currentCategory, setCurrentCategory] = useState('all');

  const onClickHandler = (teamCategory) => {
    
     const currentTeam = Team.filter(({category})=>category===teamCategory);
     setTeamMember(currentTeam);

     setCurrentCategory(teamCategory);
  }

  return (
    <>
      <Loader />
      <InnerBanner title="geothermal core team" />
      <PageHeading heading="We leverage a team built on dedicated focus, high-integrity partnerships, and a collective spirit of creativity and drive." />

      {/* <div data-aos-duration="700" data-aos="fade-up">
        <div className="team-filter-bar d-none">
          <p className="filter-title">Filter:</p>
          <div className="btn-wrap d-flex aling-items-center">
            <button
              className={`btn filter-btn ${
                currentCategory === "all" && "active"
              }`}
              onClick={() => {
                setTeamMember(Team);
                setCurrentCategory("all");
              }}
            >
              All
            </button>
            <button
              className={`btn filter-btn ${
                currentCategory === "capital" && "active"
              }`}
              onClick={() => {
                onClickHandler("capital");
              }}
            >
              Capital
            </button>
            <button
              className={`btn filter-btn ${
                currentCategory === "royalties" && "active"
              }`}
              onClick={() => {
                onClickHandler("royalties");
              }}
            >
              Royalties
            </button>
            <button
              className={`btn filter-btn ${
                currentCategory === "innovation" && "active"
              }`}
              onClick={() => {
                onClickHandler("innovation");
              }}
            >
              Innovation Partners
            </button>
            <button
              className={`btn filter-btn ${
                currentCategory === "e&p" && "active"
              }`}
              onClick={() => {
                onClickHandler("e&p");
              }}
            >
              E&P
            </button>
          </div>
        </div>
      </div> */}

      <div data-aos-duration="700" data-aos="fade-up">
        <div className="team-section-wrap">
          <motion.div layout className="gy-5 row">
            {teamMember.map((team) => {
              return (
                <motion.div
                  layout
                  className="col-xxl-3 col-md-4 col-6"
                  key={team.id}
                >
                  <div className="team-inner-wrap">
                    <div className="team-inner-img">
                      <img src={`${team.img_path}`} alt="" />
                    </div>

                    <div className="team-inner-info">
                      <h6 className="team-inner-name">{team.name}</h6>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="team-inner-position">{team.position}</p>

                        <a href={team.linkedIn} target="_blank">
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                      </div>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </motion.div>
        </div>
      </div>
      <br/>
      <Bottom />
    </>
  );
};

export default TeamPage;
