import React, { useState } from "react";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import Loader from "../layout/loader";
import PageHeading from "../pageHeading";
import Swal from "sweetalert2";

const Investor = () => {
  const [data, setData] = useState({});
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    if(!data.first_name || !data.last_name || !data.first_name || !data.email || !data.phone ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter mandatory field!",
      });
      return
    }
    try {
      let res = await fetch(
        "https://api.gfuturetech.com/api/contact/sendMail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      //   let resJson = await res.json();
      if (res.status === 200) {
        document.getElementById("create-course-form").reset();

        Swal.fire({
          icon: "success",
          title: "Submitted",
          text: "All the details are collected. We will contact you soon.",
        });
        setData({});
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Some error occured. Please try again leter.",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Loader />
      <InnerBanner title="INVESTOR" />
      <PageHeading heading="We love hearing from people. Please don’t hesitate to contact us with any questions or concerns and we’ll get back to you as soon as possible." />

      <div className="investor-form">
        <div className="form-wrap">
          <form method="post" onSubmit={handleSubmit} id="create-course-form">
            <Row className="g-4">
              <Col md="6">
                <FormLabel>First Name <span className="text-danger">*</span></FormLabel>
                <FormControl
                  placeholder="John"
                  name="first_name"
                  value={data.first_name}
                  onChange={onChange}
                  required
                />
              </Col>

              <Col md="6">
                <FormLabel>Last Name <span className="text-danger">*</span></FormLabel>
                <FormControl
                  placeholder="Doe"
                  name="last_name"
                  value={data.last_name}
                  onChange={onChange}
                  required
                />
              </Col>

              <Col md="6">
                <FormLabel>Email Address <span className="text-danger">*</span></FormLabel>
                <FormControl
                  type="email"
                  placeholder="johndoe@email.com"
                  name="email"
                  value={data.email}
                  onChange={onChange}
                  required
                />
              </Col>

              <Col md="6">
                <FormLabel>Phone number <span className="text-danger">*</span></FormLabel>
                <FormControl
                  type="number"
                  placeholder="000 000 0000"
                  name="phone"
                  value={data.phone}
                  onChange={onChange}
                  required
                />
              </Col>

              <Col md="12">
                <FormLabel>Subject <span className="text-danger">*</span></FormLabel>
                <FormControl
                  placeholder="Enter your subject for the inquiry"
                  value={data.subject}
                  name="subject"
                  onChange={onChange}
                  required
                />
              </Col>

              <Col md="12">
                <FormLabel>Message </FormLabel>
                {data.message ? (
                  <FormControl
                    as="textarea"
                    placeholder="Type you message here"
                    value={data.message}
                    rows={6}
                    name="message"
                    onChange={onChange}
                  />
                ) : (
                  <FormControl
                    as="textarea"
                    placeholder="Type you message here"
                    value={''}
                    rows={6}
                    name="message"
                    onChange={onChange}
                  />
                )}
              </Col>

              <Col md="12">
                <button type="submit" className="btn submit-btn">
                  Send Message
                </button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
};

export default Investor;
