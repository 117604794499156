import React from 'react'
import { Col, Row } from 'react-bootstrap'
import InnerBanner from '../innerBanner'
import PageHeading from '../pageHeading'
import PrimaryBgHeading from '../primaryBgHeading'
import TechnologyBox from '../technologyBox'
import t1 from '../../assets/img/rehmtechnology.png'
import t2 from '../../assets/img/arcs1.png'
import t3 from '../../assets/img/swers1.png'
import Loader from '../layout/loader'

const Technology = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="TECHNOLOGY" />
      <PageHeading
        heading="TRANSENERGY360 IS COMMITTED TO TRANSFORMING THE ENERGY LANDSCAPE USING LOW CARBON POWER GENERATION TECHNOLOGY"
        align="text-start"
      />

      <div className="small-info">
        <Row>
          <Col lg="3" className="d-none d-lg-block"></Col>
          <Col lg="9">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="small-info-text">
              The introduction of REHM, ARCS, and SWERS technologies into the landscape will create a better future for the energy industry.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <PrimaryBgHeading heading="TECHNOLOGY WE FOCUS IN" />

      <div className="techno-box-wrap">
        <Row className="gy-4">
          <Col md="6" xl="4">
            <div data-aos-duration="700" data-aos="fade-right">
              <TechnologyBox
                img={t1}
                show="d-block"
                link="/technology/rehm"
                title="REHM TECHNOLOGY"
                desc="REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. "
              />
            </div>
          </Col>

          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <TechnologyBox
                img={t2}
                show="d-block"
                link="/technology/arcs"
                title="ARCS TECHNOLOGY"
                desc="Arcs provides a means to use solar energy captured by the oceans for emission-free power generation."
              />
            </div>
          </Col>

          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <TechnologyBox
                img={t3}
                show="d-block"
                link="/technology/swers"
                title="SWERS TECHNOLOGY"
                desc="SWERS provides the ability to save water and recover waste energy in thermal power plants."
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Technology