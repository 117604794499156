import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import Team from "../team";
import jay from "../../assets/img/team/jayy.png";
import andy from "../../assets/img/team/Wick.png";
import inside from "../../assets/img/inside.png";
import Bottom from "../home/Bottom";
import Loader from "../layout/loader";
import Aos from "aos";

const AboutUs = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Loader />
      <InnerBanner title="About Us" />
      <PageHeading heading="We are TransEnergy360, a Texas-based clean energy company offering innovative technologies to support the global energy transition. Our mission is to enable countries around the world to be energy self-reliant by using our proprietary methane hydrate REHM technology." />

      <div className="about-owner">
        <div className="about-heading-wrap">
          <div className="about-heading">
            <div data-aos-duration="500" data-aos="fade-up">
              <p className="text-center">
                Expertise and Integrity - Our FOUNDERS & CEO
              </p>
            </div>
          </div>
        </div>

        <div className="owner-detail">
          <Row className="align-items-md-end justify-content-between gy-4">
            <Col lg="6">
              <div className="owner-img-wrap">
                <div data-aos-duration="700" data-aos="fade-right">
                  <Team img={jay} name="Dr. Jay Patel" position="CEO" />
                </div>
                <div
                  data-aos-duration="700"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <Team
                    img={andy}
                    name="Wickrema Singhe"
                    position="Founder & CTO"
                  />
                </div>
              </div>
            </Col>
            <Col lg="5">
              <div data-aos-duration="700" data-aos="fade-up">
                <p className="about-section-title">Dr. jay patel</p>
                <p className="about-section-desc">
                  Jaikrishna "Jay" Patel, MD is a graduate of AUC School of
                  Medicine. As a Philanthropist he has supported many
                  initiatives across various institutions and geographies. Being
                  visionary evangelist, Dr. Jay set foot into entrepreneurship
                  and so founded multiple companies to help businesses reach
                  their true potential. He is the founder and CEO of EDGE196,
                  LABS196, TECH196, and Generation Heights. He strongly believes
                  that when one startup becomes a unicorn, known as a
                  billion-dollar company, it can inspire and motivate a new
                  generation of entrepreneurs. Dr. Jay's commercial and business
                  acumen provides invaluable leadership to all his projects
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mission-wrap">
          <div className="mission-content">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="mission">
                Our mission is to use our proprietary REHM technology for
                methane hydrate extraction to improve global energy security,
                reduce carbon emission, increase farm productivity and promote
                reforestation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-inner-section">
        <div data-aos-duration="700" data-aos="fade-up">
          <h4 className="inner-about-title">
            COMMITTED TO TRANSFORMING THE ENERGY LANDSCAPE USING LOW CARBON
            POWER GENERATION
          </h4>
        </div>

        <div data-aos-duration="700" data-aos="fade-up">
          <div className="about-info-wrap">
            <div className="earth-img">
              <img src={inside} alt="" />
            </div>

            <div className="about-info-content">
              <p className="desc-text">
                The abundance and wide distribution of Methane Hydrates offers a
                global solution to sustainability, emissions and energy
                security. Further, due to this abundance it offers efficient
                electricity production for important renewable solutions such as
                hydrogen while reducing carbon emissions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Bottom />
    </>
  );
};

export default AboutUs;
