import React from 'react'
import info from '../../assets/img/info-shape.png'

const Info = () => {
  return (
    <section className='info-section' >
      <img  src={info} alt="" className="shape" />

      <div className="info-content">
      <div data-aos-duration="500" data-aos="fade-right">
        <p className="info-text">“Methane hydrates could represent a global paradigm shift in energy supply” - Ray Boswell, U.S Department of Energy</p>
        </div>
      </div>
    </section>
  )
}

export default Info
