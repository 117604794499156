import React from "react";
import InnerBanner from "../innerBanner";
import { Col, Row } from "react-bootstrap";
import ContactDetail from "./ContactDetail";
import Loader from "../layout/loader";

const Contact = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="CONTACT US" />

      <div className="contact-wrap">
        <Row className="justify-content-between">
          <Col lg="5">
            <div className="contact-info-wrap">
              <div
                data-aos-duration="700"
                data-aos="fade-up"
                data-aos-delay="1200"
              >
                <div className="contact-detail-wrap">
                  <div className="detail-title-wrap">
                    <p>Contact us</p>
                  </div>

                  <div className="contact-info">
                    <p className="contact-info-title">Address</p>
                    <p className="contact-detail">
                      2150 TOWN SQUARE PLAZA <br /> SUITE 200 <br /> SUGAR LAND,
                      TX 77479 <br /> U.S.A
                    </p>
                  </div>

                  <ContactDetail
                    title="Email"
                    displayDetail="d-none"
                    display="d-block"
                    link="info@geothermalcore.com"
                  />
                </div>
              </div>

              <div data-aos-duration="700" data-aos="fade-up">
                <div className="contact-detail-wrap">
                  <div className="detail-title-wrap">
                    <p>Contact investor relations</p>
                  </div>

                  <div className="invst-wrap">
                    <p className="invstr-name">Dr. Jay Patel</p>
                    <p className="invstr-position">CEO</p>
                    <a
                      href="mailto:jay@geothermalcore.com"
                      className="contact-link"
                    >
                      jay@geothermalcore.com
                    </a>
                  </div>

                  <div className="invst-wrap">
                    <p className="invstr-name">Dr. Michael Shoemaker</p>
                    <p className="invstr-position">COO</p>
                    <a
                      href="mailto:mshoemaker@geothermalcore.com"
                      className="contact-link"
                    >
                      mshoemaker@geothermalcore.com
                    </a>
                  </div>

                  <div className="invst-wrap">
                    <p className="invstr-name">Dr. Andrew Davidoff</p>
                    <p className="invstr-position">CTO</p>
                    <a
                      href="mailto:adavidoff@geothermalcore.com"
                      className="contact-link"
                    >
                      adavidoff@geothermalcore.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg="7" xxl="6">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="1200"
            >
              <div className="media-inq-wrap">
                <div className="media-inq">
                  <div className="detail-title-wrap">
                    <h4>For media inquiries</h4>
                  </div>

                  <div className="inq-member">
                    <p className="inq-name">John Doe</p>
                    <p className="inq-position">
                      Head of Communications & Public Affairs
                    </p>
                  </div>

                  <div className="inq-info-wrap">
                    <div className="inq-info">
                      <p>Phone</p>
                      <p>987.654.9876</p>
                    </div>

                    <div className="inq-info">
                      <p>Email</p>
                      <a href="mailto:johndoe@geothermalcore.com">
                        johndoe@geothermalcore.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Contact;
