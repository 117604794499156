import React, { useEffect } from 'react'
import Loader from '../layout/loader'
import About from './About'
import Banner from './Banner'
import Bottom from './Bottom'
import Brief from './Brief'
import Info from './Info'
import Insights from './Insights'
import Numbers from './Numbers'
import Plants from './Plants'
import Team from './Team'
import Aos from 'aos';

const Home = () => {
  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <>
      <Loader/>
      <Banner />
      <div className="main-body">
        <About title='What are Methane hydrates?' desc1="• Solid, ice-like substances formed when methane molecules are trapped within a lattice of water molecules " 
        desc2="• Often found in the ocean floor and a potentially valuable source of clean energy"
        desc3="• Methane Hydrates are very energy-dense, so a small amount contains a large amount of energy potential"

        desc4="• They could potentially provide a significant amount of energy with a relatively small amount of fuel"
        desc5="• Methane is a clean-burning fuel, so using methane hydrates for power generation would produce significantly fewer emissions than using fossil fuel"

 />
        <div data-aos="fade-up" data-aos-duration="500">
          <Brief />
        </div>
        <Numbers />
        <Insights />
        <Team />
        <About desc1="The introduction of REHM, ARCS, and SWERS technologies into the landscape will create a better future for the energy industry." title="TRANSFORMING THE ENERGY LANDSCAPE USING LOW CARBON POWER GENERATION TECHNOLOGY" />
        <Plants className='home-plant'/>
        <Info />
        <Bottom />
      </div>
    </>
  )
}

export default Home
